import ChartController from "./chart_controller";
import Highcharts from "highcharts";

export default class extends ChartController {
  async connect() {
    const categories = [
      "Trnava",
      "Senica",
      "Skalica",
      "Spišská Nová Ves",
      "Pezinok",
      "Michalovce",
      "Trenčín",
      "Bratislava I",
      "Bratislava III",
      "Galanta",
      "Stará Ľubovňa",
      "Malacky",
      "Prešov",
      "Námestovo",
      "Žilina",
      "Trebišov",
      "Humenné",
      "Košice I",
      "Bratislava II",
      "Poprad",
      "Kežmarok",
      "Nitra",
      "Levice",
      "Čadca",
      "Bardejov",
      "Považská Bystrica",
      "Veľký Krtíš",
      "Prievidza",
      "Rožňava",
      "Liptovský Mikuláš",
      "Bratislava IV",
      "Revúca",
      "Košice II",
      "Žiar nad Hronom",
      "Martin",
      "Banská Bystrica",
      "Lučenec",
      "Topoľčany",
      "Ružomberok",
      "Svidník",
      "Zvolen",
      "Nové Zámky",
      "Dolný Kubín",
      "Brezno",
      "Vranov nad Topľou",
      "Piešťany",
      "Bánovce nad Bebravou",
      "Nové Mesto nad Váhom",
      "Partizánske",
      "Dunajská Streda",
      "Košice - okolie",
      "Bratislava V",
      "Rimavská Sobota",
      "Komárno",
    ];

    const data = [
      {
        name: "2022",
        data: [
          233.28, 272.64, 231.24, 229.92, 199.56, 211.32, 251.76, 194.28, 221.76, 223.44, 202.44, 194.4, 210.48, 223.44,
          221.04, 236.64, 195, 283.2, 171.96, 224.52, 217.2, 203.4, 209.64, 175.44, 203.28, 233.64, 194.16, 178.56,
          191.16, 193.92, 164.76, 216.6, 221.28, 186.36, 223.32, 169.2, 156.36, 184.68, 224.28, 235.68, 160.68, 180,
          230.52, 199.56, 168.24, 149.28, 158.76, 273.6, 170.4, 183.24, 190.8, 197.76, 198.72, 135.12,
        ],
      },

      {
        name: "Priemer 2022",
        type: "line",
        data: [
          204, 204, 204, 204, 204, 204, 204, 204, 204, 204, 204, 204, 204, 204, 204, 204, 204, 204, 204, 204, 204, 204,
          204, 204, 204, 204, 204, 204, 204, 204, 204, 204, 204, 204, 204, 204, 204, 204, 204, 204, 204, 204, 204, 204,
          204, 204, 204, 204, 204, 204, 204, 204, 204, 204,
        ],
      },

      {
        name: "2021",
        visible: false,
        data: [
          242.4, 316.08, 262.08, 254.88, 210.36, 263.88, 244.44, 211.8, 236.76, 211.08, 204.12, 227.76, 213.12, 205.32,
          209.52, 249.12, 185.28, 233.04, 200.16, 207.84, 205.68, 220.08, 207.96, 187.08, 185.4, 207.84, 183, 219.36,
          214.8, 175.56, 194.4, 184.32, 198.72, 181.8, 201.24, 198.12, 184.08, 180.84, 187.2, 219.96, 179.04, 192.96,
          246, 192.72, 174, 179.16, 159.12, 229.32, 180.24, 180.72, 175.44, 186.12, 200.88, 139.92,
        ],
      },
      {
        name: "2020",
        visible: false,
        data: [
          247.44, 303.96, 276.48, 248.28, 192.36, 247.68, 234.36, 224.28, 248.04, 222.24, 218.28, 267.36, 207.84,
          258.24, 209.76, 230.52, 186.12, 222.96, 220.68, 188.28, 221.28, 231.84, 194.4, 185.52, 172.44, 206.16, 171.96,
          220.68, 224.04, 215.16, 216.6, 188.16, 193.08, 192.6, 202.08, 197.88, 206.64, 197.16, 168.36, 203.4, 178.32,
          186.36, 204.12, 177.24, 173.16, 205.8, 171.36, 183.72, 168.72, 181.8, 183.84, 189.6, 194.04, 153.72,
        ],
      },
      {
        name: "2019",
        visible: false,
        data: [
          326.52, 284.04, 281.4, 266.76, 258.84, 258.72, 249.36, 242.88, 242.4, 240, 237.72, 234.36, 232.68, 231.84,
          228.48, 226.32, 226.2, 226.2, 225.84, 225.48, 222.84, 221.76, 220.2, 219.24, 216.96, 216.84, 213.48, 211.8,
          209.64, 208.8, 207.84, 206.88, 205.44, 202.44, 199.56, 198.6, 197.4, 194.88, 194.88, 191.76, 189.84, 186.24,
          184.92, 184.08, 183.48, 182.64, 182.28, 182.28, 180.6, 179.04, 178.68, 176.28, 172.92, 152.76,
        ],
      },

      {
        name: 2018,
        visible: false,
        data: [
          328.68, 286.68, 311.04, 289.44, 238.8, 260.76, 268.92, 274.44, 282.84, 270.36, 172.2, 202.44, 223.08, 281.76,
          267, 234.12, 214.92, 274.32, 237.12, 225.72, 244.68, 255.84, 258.72, 282.36, 268.56, 260.04, 290.52, 246.24,
          242.04, 195.36, 268.2, 215.76, 226.68, 194.76, 199.68, 224.04, 178.68, 213.84, 215.88, 205.56, 177.96, 245.28,
          189.96, 204.72, 241.2, 243.72, 196.56, 253.92, 158.16, 213.24, 212.52, 212.28, 186, 213.24,
        ],
      },

      {
        name: 2017,
        visible: false,
        data: [
          362.88, 325.68, 303.24, 285.48, 251.16, 260.76, 257.88, 272.28, 300.24, 296.16, 181.8, 224.4, 242.76, 282.48,
          312.84, 240.36, 242.64, 323.04, 262.08, 211.92, 240.6, 259.44, 279.96, 273.6, 248.64, 225.84, 193.44, 239.28,
          260.28, 201.12, 297.84, 224.4, 267.96, 212.16, 198.12, 240.24, 188.88, 220.8, 212.76, 228.12, 191.16, 270.84,
          204, 213.48, 238.32, 253.32, 191.76, 246.12, 164.88, 251.64, 225.72, 226.2, 190.56, 203.04,
        ],
      },

      {
        name: 2016,
        visible: false,
        data: [
          372.36, 350.64, 209.88, 287.16, 201.48, 247.44, 264.6, 287.16, 310.68, 279.12, 216.24, 258.6, 224.64, 242.16,
          284.28, 225.24, 211.92, 378, 282, 186.84, 223.8, 261.72, 260.04, 267.72, 255.84, 231.72, 195.84, 216.12,
          248.04, 184.2, 293.04, 174.84, 291.36, 200.04, 213.6, 193.8, 178.2, 230.88, 217.68, 308.64, 172.08, 251.04,
          209.28, 176.76, 230.4, 279.84, 198.6, 260.88, 171.84, 283.08, 212.4, 261.96, 182.76, 214.44,
        ],
      },

      {
        name: 2015,
        visible: false,
        data: [
          363.6, 343.8, 222.36, 284.04, 232.56, 265.56, 275.64, 327.24, 303.72, 286.32, 212.04, 268.92, 227.28, 236.64,
          293.04, 228, 217.56, 297.12, 302.76, 194.4, 187.2, 300.96, 311.16, 286.8, 204.24, 212.28, 166.2, 235.56,
          367.8, 243.12, 317.04, 197.64, 306, 216.96, 211.32, 193.8, 186.24, 219.84, 181.56, 242.28, 183.12, 270.24,
          178.44, 178.32, 233.88, 232.2, 209.4, 236.76, 168.96, 309.72, 239.88, 273, 210.84, 203.28,
        ],
      },

      {
        name: 2014,
        visible: false,
        data: [
          436.68, 348.6, 248.04, 272.64, 239.28, 276.6, 383.52, 333.6, 327.6, 418.56, 247.8, 284.28, 236.28, 258,
          307.32, 241.56, 256.68, 294, 270.72, 246.24, 248.04, 300.12, 273.12, 281.28, 243.72, 237.24, 237, 263.76,
          325.2, 225.6, 303.84, 204, 278.4, 229.68, 271.2, 214.08, 208.92, 260.16, 227.04, 240.48, 197.16, 260.76,
          255.96, 207.12, 250.32, 312.48, 211.44, 226.2, 173.4, 407.52, 226.08, 265.8, 213.6, 222.24,
        ],
      },

      {
        name: 2013,
        visible: false,
        data: [
          462.12, 368.04, 261.24, 303.12, 338.28, 277.92, 368.88, 347.88, 368.04, 443.04, 278.52, 280.32, 242.88, 192.6,
          293.28, 245.04, 276.36, 235.08, 349.2, 259.68, 236.04, 325.2, 312.24, 261.6, 252.48, 239.04, 257.16, 265.56,
          385.32, 235.32, 216.36, 213.84, 262.92, 371.76, 274.8, 229.92, 212.88, 299.04, 245.04, 285.96, 246.36, 264.12,
          183.24, 331.68, 228.36, 385.68, 228, 225.72, 187.32, 448.92, 241.08, 243.72, 225.48, 220.56,
        ],
      },

      {
        name: 2012,
        visible: false,
        data: [
          580.56, 272.52, 184.56, 288.6, 406.68, 261.36, 310.92, 330.6, 280.92, 398.88, 254.76, 328.56, 258.96, 252.48,
          292.2, 229.44, 293.52, 235.32, 315, 260.04, 217.2, 284.52, 267.24, 260.52, 243.24, 221.04, 231.84, 268.2,
          327.36, 239.52, 255.36, 223.8, 269.76, 256.56, 263.52, 211.44, 226.8, 223.08, 237.24, 258.72, 199.44, 261.36,
          172.44, 245.64, 207, 322.2, 207, 219, 163.32, 433.32, 220.2, 263.88, 223.68, 219.36,
        ],
      },

      {
        name: 2011,
        visible: false,
        data: [
          474, 269.64, 250.2, 327.6, 422.28, 298.32, 378.72, 311.16, 278.52, 488.4, 207.96, 326.76, 341.28, 298.56, 294,
          287.64, 253.8, 387.6, 385.56, 260.16, 206.64, 249.72, 304.08, 276.96, 275.16, 218.4, 238.2, 267, 319.92,
          239.04, 326.76, 293.16, 360.12, 272.88, 305.64, 327.36, 274.44, 263.64, 288.6, 249, 276.96, 253.44, 249.72,
          238.44, 253.56, 382.56, 213.36, 232.32, 174.96, 428.04, 253.08, 297, 264, 230.28,
        ],
      },

      {
        name: 2010,
        visible: false,
        data: [
          376.68, 261, 259.56, 326.04, 430.56, 283.8, 331.56, 342.6, 269.04, 398.4, 219.72, 369.72, 394.68, 256.32,
          298.8, 291.72, 322.44, 348.12, 384.72, 287.16, 244.8, 284.16, 266.4, 267.6, 224.28, 228, 179.16, 282, 312.72,
          229.8, 317.4, 390.96, 276.96, 316.56, 359.52, 281.4, 254.16, 202.2, 290.16, 248.4, 232.92, 238.92, 189.6,
          212.76, 248.52, 499.8, 207.72, 260.64, 174.24, 369.48, 228.72, 313.44, 271.44, 208.2,
        ],
      },
    ];

    const colorsByName = {
      2022: this.colors[0],
      "Priemer 2022": this.colors[1],
      2021: this.colors[2],
      2020: this.colors[3],
      2019: this.colors[4],
      2018: this.colors[5],
      2017: this.colors[6],
      2016: this.colors[2],
      2015: this.colors[3],
      2014: this.colors[4],
      2013: this.colors[5],
      2012: this.colors[6],
      2011: this.colors[2],
      2010: this.colors[3],
    };

    const series = data.map((value, i) => ({
      type: "column",

      ...value,

      color:
        value.type === "line"
          ? Highcharts.Color(colorsByName[value.name]).setOpacity(0.75).get("rgba")
          : {
              linearGradient: {
                x1: 1,
                y1: 0,
                x2: 1,
                y2: 1,
              },

              stops: [
                [0, Highcharts.Color(colorsByName[value.name]).setOpacity(1).get("rgba")],
                [1, Highcharts.Color(colorsByName[value.name]).setOpacity(0.25).get("rgba")],
              ],
            },
    }));

    this.chart = Highcharts.chart(this.element, {
      colors: this.colors,

      credits: {
        enabled: false,
      },

      chart: {
        type: "xy",
        backgroundColor: "transparent",
        height: 400,
      },

      legend: {
        enabled: true,
        layout: "horizontal",
        align: "left",
        verticalAlign: "top",
        x: 50,
        y: 0,
        itemMarginTop: 2,
        itemMarginBottom: 2,
        floating: true,
        itemStyle: {
          fontWeight: "normal",
        },
      },

      tooltip: {
        shared: true,
      },

      title: {
        text: undefined,
      },

      xAxis: [{ categories }],

      yAxis: {
        title: {
          text: undefined,
        },

        maxPadding: 0.75,
      },

      plotOptions: {
        line: {
          fillOpacity: 0,
          lineWidth: 2,
          marker: {
            radius: 0,
          },
        },

        column: {
          stacking: "normal",
        },
      },

      series,
    });

    setTimeout(() => this.resizeChart(), 0);
    (window.onresize = () => this.resizeChart())();
  }
}
